import { getCardSorter } from "../../common/helpers";
import {PlayGameProps, card} from "../../common/models"
import Card from "./Card";
import PointInfoDisplay from "./PointInfoDisplay";

const EndOfGame: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    function isCardWorthPoints(card: card): boolean {
        return card.value == 5 || card.value == 10 || card.value == 13
    }
    return (
        <div style={{margin: "10px"}}>
            <h1>Game End</h1>
            <PointInfoDisplay gameState={gameState} playerId={playerId}></PointInfoDisplay>
            <div>
                <h2>底牌:</h2>
                {gameState.dipai.sort(getCardSorter(gameState)).map(card => <Card card={card} highlight={isCardWorthPoints(card)}></Card>)}
            </div>
        </div>
    )
}

export default EndOfGame;
