import { Button } from "@mui/material";
import { getPlayerIndex, makePostRequest } from "../../common/helpers";
import {PlayGameProps} from "../../common/models"
import DeclareTrump from "./DeclareTrump";
import PlayerHand from "./PlayerHand";
import AwaitPlayerConfirm from "./AwaitPlayerConfirm";

const ConfirmTrump: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    function confirmTrump() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            alert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "confirmtrump",
                playerIndex: playerIndex
            },
            (response: string) => console.log(response)
        )
    }

    return (
        <div style={{margin: "10px"}}>
            { gameState.trumpConfirm[getPlayerIndex(gameState, playerId)] ?
                <div>
                    <AwaitPlayerConfirm
                        gameState={gameState}
                        playerId={playerId}
                        awaitingText="Waiting for others to confirm trump..."
                        playerReadyStatus={gameState.trumpConfirm}
                    ></AwaitPlayerConfirm>
                </div>
            :
                <div>
                <Button style={{backgroundColor: "#7B4157"}} onClick={confirmTrump} variant="contained">Confirm 主</Button>
                <DeclareTrump gameState={gameState} playerId={playerId}></DeclareTrump>
                </div>
            }
            <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
        </div>
    )
}

export default ConfirmTrump;
