import './App.css';
import Game from './components/Game'

function App() {
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
      backgroundColor: "#F3E4E2",
      caretColor: "transparent"
    }}><Game></Game></div>
  );
}

export default App;
