import { Button } from "@mui/material";
import { getPlayerIndex, makePostRequest } from "../../common/helpers";
import {PlayGameProps, gameState} from "../../common/models"
import DeclareTrump from "./DeclareTrump";
import PlayerHand from "./PlayerHand";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Check from '../../images/check.png'
import X from '../../images/x.png'

interface AwaitPlayerConfirmProps {
    gameState: gameState;
    playerId: string;
    awaitingText: string;
    playerReadyStatus: boolean[];
}
const AwaitPlayerConfirm: React.FC<AwaitPlayerConfirmProps> = ({gameState, playerId, awaitingText, playerReadyStatus}) => {
    function renderCheckOrX(ready: boolean): JSX.Element {
        if (ready) {
            return (<img style={{width: "30px"}} src={Check}/>)
        }

        return (<img style={{width: "30px"}} src={X}/>)
    }

    return (
        <div style={{margin: "10px"}}>
            <h3>{awaitingText}</h3> 
            <TableContainer component={Paper} sx={{borderRadius: "10px", minWidth: 650, maxWidth: 650, marginLeft: "auto", marginRight: "auto"}}>
                <Table sx={{ minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            {gameState.playerIds.map((playerId) => <TableCell align="center">{playerId}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        >
                            {playerReadyStatus.map((ready) => {
                                return (
                                    <TableCell align="center">{renderCheckOrX(ready)}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default AwaitPlayerConfirm;
