import { gameState } from "../common/models";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface WaitingForPlayersProps {
    gameState: gameState
    waitingText: string
}

const WaitingForPlayers: React.FC<WaitingForPlayersProps> = ({gameState, waitingText}) => {
    function renderTableCells() {
        let joinedPlayers: JSX.Element[] = gameState.playerIds.map((playerId, index) => {
            return (
                gameState.joinGameConfirm[index] ? 
                <TableCell align="center">{playerId}</TableCell>
                :                      
                <TableCell align="center">...</TableCell>
            )
        });
        for(var i = gameState.playerIds.length; i < gameState.playerCount; i++) {
            joinedPlayers.push(
                <TableCell align="center">...</TableCell>
            )
        }
        return joinedPlayers;
    }

    function renderPlayerHeaders(): JSX.Element[] {
        let headerElements: JSX.Element[] = [];
        for (var i = 0; i < gameState.playerCount; i++) {
            headerElements.push(<TableCell align="center">Player {i + 1}</TableCell>)
        }
        return headerElements;
    }

    return (
        <div>
            <h3>GameId: {gameState.gameId}</h3>
            <h3>{waitingText}</h3>
            <TableContainer component={Paper} sx={{borderRadius: "10px", minWidth: 650, maxWidth: 650, marginLeft: "auto", marginRight: "auto"}}>
                <Table sx={{ minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            {renderPlayerHeaders()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        >
                            {renderTableCells()}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default WaitingForPlayers;
