import {
    SmallJoker,
    BigJoker,
    C2,
    D2,
    H2,
    S2,
    C3,
    D3,
    H3,
    S3,
    C4,
    D4,
    H4,
    S4,
    C5,
    D5,
    H5,
    S5,
    C6,
    D6,
    H6,
    S6,
    C7,
    D7,
    H7,
    S7,
    C8,
    D8,
    H8,
    S8,
    C9,
    D9,
    H9,
    S9,
    CT,
    DT,
    HT,
    ST,
    CJ,
    DJ,
    HJ,
    SJ,
    CQ,
    DQ,
    HQ,
    SQ,
    CK,
    DK,
    HK,
    SK,
    CA,
    DA,
    HA,
    SA,
    Blank,
 } from "../images";
import { card } from "./models";

export function getImageForCard(card: card) {
    if (card.suit == "blank") {
        return Blank;
    }
    
    if (card.suit == "smalljoker") {
        return SmallJoker;
    }

    if (card.suit == "bigjoker") {
        return BigJoker;
    }

    return cardToImage[card.suit][card.value];
}
const cardToImage: { [suit: string] : { [value: number] : any; }; } = {
    "clubs": {
        2: C2,
        3: C3,
        4: C4,
        5: C5,
        6: C6,
        7: C7,
        8: C8,
        9: C9,
        10: CT,
        11: CJ,
        12: CQ,
        13: CK,
        14: CA,
    },
    "diamonds": {
        2: D2,
        3: D3,
        4: D4,
        5: D5,
        6: D6,
        7: D7,
        8: D8,
        9: D9,
        10: DT,
        11: DJ,
        12: DQ,
        13: DK,
        14: DA,
    },
    "hearts": {
        2: H2,
        3: H3,
        4: H4,
        5: H5,
        6: H6,
        7: H7,
        8: H8,
        9: H9,
        10: HT,
        11: HJ,
        12: HQ,
        13: HK,
        14: HA,
    },
    "spades": {
        2: S2,
        3: S3,
        4: S4,
        5: S5,
        6: S6,
        7: S7,
        8: S8,
        9: S9,
        10: ST,
        11: SJ,
        12: SQ,
        13: SK,
        14: SA,
    }
 }