import { gameState, card } from "../common/models";
import { HTTP_BASE_URL } from "./constants";
import spades from "../images/spades.png";
import hearts from "../images/hearts.png";
import diamonds from "../images/diamonds.png";
import clubs from "../images/clubs.png";

export function getPlayerIndex(gameState: gameState, playerId: string) {
    for (var i = 0; i < gameState.playerIds.length; i++) {
        if (gameState.playerIds[i] === playerId) {
            return i;
        } 
    }
    return -1;
}

const suitToImage: { [suit: string] : any; } = {
    "clubs": clubs,
    "diamonds": diamonds,
    "hearts": hearts,
    "spades": spades,
}

export function getSuitImage(suit: string) {
    return suitToImage[suit]
}

const suitOrder = ["clubs", "diamonds", "hearts", "spades", "smalljoker", "bigjoker"]

function getSuitOrderIndex(suit: string): number {
    for (var i = 0; i < suitOrder.length; i++) {
        if (suitOrder[i] === suit) {
            return i;
        }
    }
    return -1;
}

const cardTiers = ["nontrump", "normaltrump", "numbertrump", "numbersuittrump", "smalljoker", "bigjoker"]

function getCardTierIndex(tier: string): number {
    for (var i = 0; i < cardTiers.length; i++) {
        if (cardTiers[i] === tier) {
            return i;
        }
    }
    return -1;
}

function getCardTier(card: card, gameState: gameState): string {
    if (card.suit == "smalljoker" || card.suit == "bigjoker") { // is a joker
        return card.suit;
    }
    if (gameState.trumpNumber == card.value) { // is a trump number
        if (gameState.trumpSuit == card.suit) { // is trump number of the trump suit
            return "numbersuittrump";
        }

        return "numbertrump";
    }

    if (gameState.trumpSuit == "undecided") { // no trump yet
        return "nontrump";
    }

    if (gameState.trumpSuit == card.suit) {
        return "normaltrump"
    }

    return "nontrump";
}

export function getSuitFilter(gameState: gameState, suit: string): (c: card) => boolean {
    return function(c: card): boolean {
        const cardTier = getCardTierIndex(getCardTier(c, gameState));
        if (cardTier != 0 ) {
            return suit == "trump";
        }

        return c.suit == suit;
    }
}

export function getCardSorter(gameState: gameState): (c1: card, c2: card) => number {
    return function(c1: card, c2: card): number {
        const c1tierIndex = getCardTierIndex(getCardTier(c1, gameState));
        const c2tierIndex = getCardTierIndex(getCardTier(c2, gameState));
        if (c1tierIndex < c2tierIndex) {
            return -1;
        }
        if (c1tierIndex > c2tierIndex) {
            return 1;
        }
        //cards are the same tier

        if (c1tierIndex >= 3) { // card is at least numbersuittrump
            return 0;
        }

        if (c1tierIndex == 2) { // card is numbertrump
            const suitOrderIndex1 = getSuitOrderIndex(c1.suit);
            const suitOrderIndex2 = getSuitOrderIndex(c2.suit);
            if (suitOrderIndex1 < suitOrderIndex2) {
                return -1;
            }
            else {
                return 1;
            }
        }

        if (c1.suit === c2.suit) {
            if (c1.value < c2.value) {
                return -1;
            }
            else if (c1.value === c2.value) {
                return 0;
            }
            else {
                return 1;
            }
        }
    
        const suitOrderIndex1 = getSuitOrderIndex(c1.suit);
        const suitOrderIndex2 = getSuitOrderIndex(c2.suit);
    
        if (suitOrderIndex1 < suitOrderIndex2) {
            return -1;
        }
        else {
            return 1;
        }
    }
}
export function cardOrderSort(c1: card, c2: card): number {
    if (c1.suit === c2.suit) {
        if (c1.value < c2.value) {
            return -1;
        }
        else if (c1.value === c2.value) {
            return 0;
        }
        else {
            return 1;
        }
    }

    const suitOrderIndex1 = getSuitOrderIndex(c1.suit);
    const suitOrderIndex2 = getSuitOrderIndex(c2.suit);

    if (suitOrderIndex1 < suitOrderIndex2) {
        return -1;
    }
    else {
        return 1;
    }
}

export function cardsAreEqual(card1: card, card2: card): boolean {
    return card1.suit == card2.suit && card1.value == card2.value;
}

export function isPlayingJoker(gameState: gameState): boolean {
    return gameState.trumpNumber == 15
}

export function makePostRequest(urlPath: string, body: any, onSuccess: (response: string) => void, alertError: boolean = true, onFailure: (response: string) => void = (response: string) => {}) {
    fetch(HTTP_BASE_URL + "/api/v1/" + urlPath, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
    .then(response => {
        response.text().then(text => {
            if (!response.ok) {
                onFailure(text);
                if (alertError) {
                    alert("Failed: " + text);
                }
            }
            else {
                onSuccess(text);
            }
        })
    })
    .catch(error => alert(error))
}

export function makeGetRequest(urlPath: string, onSuccess: (jsonResponse: any) => void) {
    fetch(HTTP_BASE_URL + "/api/v1/" + urlPath, {
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
    })
    .then(response => response.json())
    .then((json: any) => {
        onSuccess(json);
    })
    .catch(error => alert(error))
}

export function makeDeleteRequest(urlPath: string, onSuccess: (jsonResponse: any) => void) {
    fetch(HTTP_BASE_URL + "/api/v1/" + urlPath, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
        },
    })
    .then(response => response.json())
    .then((json: any) => {
        onSuccess(json);
    })
    .catch(error => alert(error))
}
