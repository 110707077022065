import {PlayGameProps, card, gameState} from "../../common/models"
import {getPlayerIndex, getCardSorter, getSuitFilter, cardsAreEqual} from "../../common/helpers";
import Card from "./Card";
import { dealerTeamColor } from "../../common/constants";

interface PlayerHandProps {
    gameState: gameState;
    playerId: string;
    onClick?: (card: card) => void;
}

const PlayerHand: React.FC<PlayerHandProps> = ({gameState, playerId, onClick = (_: card) => {}}) => {
    const playerHand = gameState.playerHands[getPlayerIndex(gameState, playerId)];
    const mostRecentCardId = playerHand.length == 0 ? -1 : playerHand[playerHand.length - 1].id;
    function getSuitHand(suit: string): card[] {
        return (
            playerHand.filter(getSuitFilter(gameState, suit))
        );
    }

    function isMostRecentCard(card: card): boolean {
        return mostRecentCardId != -1 && card.id == mostRecentCardId;
    }

    function shouldHighlightCard(card: card): boolean {
        if (gameState.phase == "drawing") {
            return isMostRecentCard(card);
        }
        
        if (gameState.phase == "playgame") {
            return gameState.dealerPartnerIndex == -1 && card.suit == gameState.calledCard.suit && card.value == gameState.calledCard.value;
        }

        return false;
    }

    function getHighlightColor(card: card): string {
        if (gameState.phase == "drawing" && isMostRecentCard(card)) {
            return "black";
        }
        
        if (gameState.phase == "playgame" && gameState.dealerPartnerIndex == -1 && cardsAreEqual(card, gameState.calledCard)) {
            return "#7B4157";
        }

        return "";
    }

    const suits = ["clubs", "diamonds", "hearts", "spades", "trump"]

    return (
        <div style={{margin: "10px"}}>
            {suits.map((suit) => {
                return (
                    <div>
                        {getSuitHand(suit).sort(getCardSorter(gameState)).map(card => <Card card={card} highlightColor={getHighlightColor(card)} highlight={shouldHighlightCard(card)} onClick={() => onClick(card)}></Card>)}
                    </div>
                )
            })}
        </div>
    )
}

export default PlayerHand;
