import { getImageForCard } from "../../common/cardImageHelper";
import { card } from "../../common/models";

interface CardProps {
    card: card;
    highlightColor?: string
    highlight?: boolean;
    onClick?: () => void;
    transparent?: boolean;
    showBack?: boolean;
}

const Card: React.FC<CardProps> = ({card, highlightColor = "black", highlight = false, onClick = () => {}, transparent = false, showBack = false}) => {
    if (showBack) {
        card = {
            id: 0,
            suit: "blank",
            value: 0
        }
    }
    let border = "2px solid " + highlightColor;
    if (!highlight) {
        border = "";
    }
    
    let opacity = transparent ? 0.5 : 1;

    return (
        <img style={{height: 130, margin:"1px", border: border, opacity: opacity}} src={getImageForCard(card)} onClick={() => onClick()}/>
    )
}

export default Card;