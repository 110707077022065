import {PlayGameProps} from "../../common/models"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getImageForCard } from "../../common/cardImageHelper";
import { getPlayerIndex } from "../../common/helpers";

const PointInfoDisplay: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    function getCalledCardDisplay(): string | JSX.Element {
        if (gameState.calledCard.suit == "") {
            return "?";
        }

        return <img style={{height: "90px"}} src={getImageForCard(gameState.calledCard)}/>
    }

    function getPointHeaders(): (JSX.Element | null)[] | JSX.Element {
        if (gameState.dealerPartnerIndex == -1) {
            return getPreJoinedTeamPointHeaders()
        }

        return getPostJoinedTeamPointHeaders()
    }

    function getPreJoinedTeamPointHeaders(): (JSX.Element | null)[] {
        return gameState.playerIds.map((playerId) => {
            if (getPlayerIndex(gameState, playerId) == gameState.dealerIndex) {
                return (null);
            }
            return (
                <TableCell align="center">{playerId}</TableCell>
            );
        });
    }

    function getPostJoinedTeamPointHeaders(): JSX.Element {
        let nonDealerTeamPlayers: string[] = [];
        for(var i = 0; i < gameState.playerIds.length; i++) {
            if (i == gameState.dealerIndex || i == gameState.dealerPartnerIndex) {
                continue
            }
            nonDealerTeamPlayers.push(gameState.playerIds[i])
        }

        let nonDealerTeamPlayersString = nonDealerTeamPlayers.join(" + ");
        return (
            <TableCell align="center">{nonDealerTeamPlayersString}</TableCell>
        )
    }

    function getPointCounts(): (JSX.Element | null)[] | JSX.Element {
        if (gameState.dealerPartnerIndex == -1) {
            return getPreJoinedTeamPointCounts()
        }

        return getPostJoinedTeamPointCount()
    }

    function getPreJoinedTeamPointCounts(): (JSX.Element | null)[] {
        return gameState.points.map((points, index) => {
            if (index == gameState.dealerIndex) {
                return (null);
            }
            return (
                <TableCell align="center">{points} 分</TableCell>
            );
        })
    }

    function getPostJoinedTeamPointCount(): JSX.Element {
        let nonDealerTeamPointsCount = 0
        for(var i = 0; i < gameState.points.length; i++) {
            if (i == gameState.dealerIndex || i == gameState.dealerPartnerIndex) {
                continue
            }
            nonDealerTeamPointsCount += gameState.points[i];
        }

        return (
            <TableCell align="center">{nonDealerTeamPointsCount}</TableCell>
        )
    }

    function getDealerName(): JSX.Element {
        if (gameState.dealerIndex == -1) {
            return (
                <TableCell align="center">?</TableCell>
            )
        }
        return (
            <TableCell align="center">{gameState.playerIds[gameState.dealerIndex]}</TableCell>
        )
    }

    return (
        <div>
            <TableContainer component={Paper} sx={{borderRadius: "10px", minWidth: "30vw", maxWidth: 650, marginLeft: "auto", marginRight: "auto"}}>
                <Table sx={{ minWidth: "30vw"}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Dealer</TableCell>
                            {!gameState.lockedTeams && <TableCell align="center">叫的牌</TableCell>}
                            {getPointHeaders()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {getDealerName()}
                            {!gameState.lockedTeams && <TableCell align="center">{getCalledCardDisplay()}</TableCell>}
                            {getPointCounts()}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default PointInfoDisplay;
