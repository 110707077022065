import { Button } from "@mui/material";
import {gameState} from "../common/models"
import GameStartOptions from "./GameStartOptions";
import WaitingForPlayers from "./WaitingForPlayers";
import ConfirmTrump from "./play/ConfirmTrump";
import DeckDraw from "./play/DeckDraw";
import DiPai from "./play/DiPai";
import EndOfGame from "./play/EndOfGame";
import PlayGame from "./play/PlayGame";
import { getPlayerIndex, makePostRequest } from "../common/helpers";
import Header from "./play/Header";
import bag from "../images/zaffo_full.jpg";
import CallFriend from "./play/CallFriend";
import FanDi from "./play/FanDi";


interface GameViewProps {
    gameState: gameState;
    playerId: string;
    connectToGameRoom: (gameId: string, isNewGame: boolean) => void;
    gameId: React.MutableRefObject<string>;
}

const GameView: React.FC<GameViewProps> = ({gameState, playerId, connectToGameRoom, gameId}) => {
    const WAITING_JOIN = "Waiting for other players to join..."
    const WAITING_HOST_START = "Waiting for host to start game..."

    function isDrawingPhase(): boolean {
        return gameState.phase === "drawing"
    }

    function isConfirmTrumpPhase(): boolean {
        return gameState.phase === "confirmtrump"    
    }

    function isFanDiPhase(): boolean {
        return gameState.phase === "fandi"
    }

    function isDiPaiPhase(): boolean {
        return gameState.phase === "dipai"
    }

    function isCallFriendPhase(): boolean {
        return gameState.phase === "callfriend"
    }

    function isPlayGamePhase(): boolean {
        return gameState.phase === "playgame"
    }

    function isEndOfGamePhase(): boolean {
        return gameState.phase === "endofgame"
    }

    function isWaitingForAllPlayers(): boolean {
        for (var i = 0; i < gameState.joinGameConfirm.length; i++) {
            if (!gameState.joinGameConfirm[i]) {
                return true;
            }
        }
        return false;
    }

    function gameIsStarted(): boolean {
        return gameState.started;
    }

    function renderGameOptionsOrWaitingForHost() {
        if (isWaitingForAllPlayers() || gameIsStarted()) {
            return null;
        }

        if (gameState.playerIds[0] === playerId) {
            return <GameStartOptions gameId={gameState.gameId} playerIds={gameState.playerIds}></GameStartOptions>
        }

        return <WaitingForPlayers gameState={gameState} waitingText={WAITING_HOST_START}></WaitingForPlayers>
    }

    function connectToNewGame() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            alert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.nextGameId + "/confirmJoin",
            {
                playerIndex: playerIndex
            },
            (_: string) => {
                gameId.current = gameState.nextGameId;
                connectToGameRoom(gameId.current, true)
            }
        )
    } 

    function renderStartedGame() {
        if (!gameIsStarted()) {
            return null;
        }

        return (
            <div>
                <Header gameState={gameState} playerId={playerId}></Header>
                {playerId == gameState.playerIds[0] ?
                <h3>GameId: {gameState.gameId}</h3> : null}
                {isDrawingPhase()? <DeckDraw gameState={gameState} playerId={playerId}></DeckDraw> : null }
                {isConfirmTrumpPhase()? <ConfirmTrump gameState={gameState} playerId={playerId}></ConfirmTrump> : null}
                {isFanDiPhase()?  <FanDi gameState={gameState} playerId={playerId}></FanDi> : null}
                {isDiPaiPhase()?  <DiPai gameState={gameState} playerId={playerId}></DiPai> : null}
                {isCallFriendPhase()?  <CallFriend gameState={gameState} playerId={playerId}></CallFriend> : null}
                {isPlayGamePhase()? <PlayGame gameState={gameState} playerId={playerId}></PlayGame> : null}
                {isEndOfGamePhase()? <EndOfGame gameState={gameState} playerId={playerId}></EndOfGame> : null}
                {isEndOfGamePhase()? <Button style={{backgroundColor: "#7B4157"}} onClick={connectToNewGame} variant="contained">Play again</Button> : null }
            </div>
        )
    }

    return (
        <div>
            { !gameIsStarted() ? <img style={{height: "200px", margin: "10px"}} src={bag} alt="Zaffo"/> : null }
            { isWaitingForAllPlayers() ? <WaitingForPlayers gameState={gameState} waitingText={WAITING_JOIN}></WaitingForPlayers>
            :
            <div>
            {renderGameOptionsOrWaitingForHost()}
            {renderStartedGame()}
            </div>
            }
            
        </div>
    )
}

export default GameView;
