import { Button } from "@mui/material";
import { getCardSorter, getPlayerIndex, makePostRequest } from "../../common/helpers";
import { card, PlayGameProps } from "../../common/models"
import Card from "./Card";
import { dealerTeamColor, nonDealerTeamColor } from "../../common/constants";

const CurrentRound: React.FC<PlayGameProps> = ({ gameState, playerId }) => {
    const currentRound = gameState.rounds[gameState.currentRound]
    const currentPlayerIndex = getPlayerIndex(gameState, playerId);

    function removeCardFromRound(card: card) {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            alert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "removecardfromround",
                playerIndex: playerIndex,
                card: card,
            },
            (response: string) => console.log(response)
        )
    }

    function playCards() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            alert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "playcardsforround",
                playerIndex: playerIndex,
            },
            (response: string) => console.log(response)
        )
    }

    function shouldShowCards(playedIndex: number): boolean {
        return currentRound.hasPlayed[playedIndex];
    }

    function haveAllPlayersPlayed(): boolean {
        for (var i = 0; i < currentRound.hasPlayed.length; i++) {
            if (!currentRound.hasPlayed[i]) {
                return false;
            }
        }

        return true;
    }

    function renderCardsForPlayer(playerIndex: number): JSX.Element[] | null {
        if (shouldShowCards(playerIndex))
        {
            return (
                currentRound.cardsPlayed[playerIndex].sort(getCardSorter(gameState)).map(card => <Card card={card}></Card>)
            )
        } 
        if (currentPlayerIndex == playerIndex) {
            return (
                currentRound.cardsPlayed[playerIndex].sort(getCardSorter(gameState)).map(card => <Card transparent card={card} onClick={() => removeCardFromRound(card)}></Card>)
            )
        }
        return null;
    }

    function displayRoundCardsForPlayer(playerIndex: number): JSX.Element {
        let border = "2px solid #A49393";
        if (playerIndex == gameState.activePlayerIndex && !haveAllPlayersPlayed()) {
            border = "5px solid #7B4157";
        }
        else if (haveAllPlayersPlayed() && currentRound.winnerIndex == playerIndex) {
            border = "5px solid black";
        }
        
        let isTurnToPlay = gameState.activePlayerIndex == currentPlayerIndex && gameState.activePlayerIndex == playerIndex && !haveAllPlayersPlayed();
        return (
            <div style={{ margin: "5px", border: border, width: "100%", minHeight: 200, borderRadius: "10px", backgroundColor: getBackgroundColorForPlayer(playerIndex)}}>
                {isTurnToPlay ?
                    <div style={{margin: "10px"}}><Button style={{backgroundColor: "#7B4157"}} onClick={playCards} variant="contained">Play Cards</Button></div>
                    :
                    <h3>{gameState.playerIds[playerIndex]}</h3>
                }
                {renderCardsForPlayer(playerIndex)}
            </div>
        )
    }

    function getBackgroundColorForPlayer(playerIndex: number): string {
        if (gameState.dealerPartnerIndex == -1) {
            if (playerIndex == gameState.dealerIndex) {
                return dealerTeamColor;
            }

            return "";
        }

        if (playerIndex == gameState.dealerIndex || playerIndex == gameState.dealerPartnerIndex) {
            return dealerTeamColor;
        }

        return nonDealerTeamColor;
    }

    function renderDisplayForPlayerCount(): JSX.Element {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {gameState.playerIds.map((_, index) => displayRoundCardsForPlayer(index))}
                </div>
            </div>
        )
    }

    return (
        <div style={{ margin: "10px" }}>
            <div>
            {renderDisplayForPlayerCount()}
            </div>
        </div>
    )
}

export default CurrentRound;
