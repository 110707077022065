import { Button } from "@mui/material";
import { makePostRequest } from "../../common/helpers";
import { PlayGameProps } from "../../common/models"
import PlayerHand from "./PlayerHand";
import Card from "./Card";

const FanDi: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    function fanDi() {
        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "fandi"
            },
            (response: string) => console.log(response)
        )
    }

    function advanceFromFanDi() {
        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "advancefromfandi"
            },
            (response: string) => console.log(response)
        )
    }

    const dipai = gameState.deck; 

    function showDiPai(): JSX.Element {
        return (
            <div>
                <h2>底牌</h2>
                {
                    dipai.map((card, index) => 
                        <Card
                            highlight={card.id == gameState.fanDiBiggestCard.id}
                            showBack={index >= gameState.fanDiIndex}
                            card={card} 
                            onClick={index == gameState.fanDiIndex ? fanDi : () => {}}
                        ></Card>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{margin: "10px"}}>
            {showDiPai()}
            {gameState.fanDiIndex == dipai.length ? 
            <Button 
                style={{marginTop: "10px", backgroundColor: "#7B4157"}} 
                onClick={advanceFromFanDi} 
                variant="contained"
            >去底牌</Button> : null }
            <h2>Hand</h2>
            <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
        </div>
    )
}

export default FanDi;
