import {PlayGameProps} from "../../common/models"
import { Button, FormControl, FormLabel, Radio, RadioGroup, Switch } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {getPlayerIndex, makePostRequest} from "../../common/helpers";
import DeclareTrump from "./DeclareTrump";
import { useEffect, useState, useRef } from "react";
import PlayerHand from "./PlayerHand";

const DeckDraw: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    const [autoDraw, setAutoDraw] = useState(true);
    const [isDrawing, setIsDrawing] = useState(false);
    const [autoDrawSpeed, setAutoDrawSpeed] = useState("fast");
    const playerIndex = getPlayerIndex(gameState, playerId);
    const prevGameState = useRef(gameState)

    const autoDrawSpeedToMsMapping: {[speed: string]: number} = {
        "slow": 1000,
        "medium": 500,
        "fast": 100
    }

    useEffect(() => {
        if (prevGameState.current.activePlayerIndex == playerIndex && gameState.activePlayerIndex != playerIndex) {
            setIsDrawing(false);
        }

        prevGameState.current = gameState;
        if (autoDraw && !isDrawing && playerIndex == gameState.activePlayerIndex) {
            setIsDrawing(true);
            setTimeout(function() {
                drawCard();
            }, autoDrawSpeedToMsMapping[autoDrawSpeed]);
        };

    }, [autoDraw, gameState, playerIndex, isDrawing, autoDrawSpeed])

    function isTurnToDraw() {
        return gameState.activePlayerIndex === getPlayerIndex(gameState, playerId);
    }

    function drawCard() {
        let playerIndex = getPlayerIndex(gameState, playerId);
        if (playerIndex === -1) {
            alert("Error finding player in game");
            return;
        }

        makePostRequest(
            "game/" + gameState.gameId + "/action",
            {
                actionType: "draw",
                playerIndex: playerIndex
            },
            (response: string) => console.log(response),
            false
        )
    }

    function onAutoDrawToggled(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setAutoDraw(checked);
    }

    function autoDrawSpeedChanged(_: React.ChangeEvent<HTMLInputElement>, value: string) {
        setAutoDrawSpeed(value);
    }

    return (
        <div style={{margin: "10px"}}>
            <DeclareTrump gameState={gameState} playerId={playerId}></DeclareTrump>
            <PlayerHand gameState={gameState} playerId={playerId}></PlayerHand>
            <Button style={{backgroundColor: "#7B4157"}} onClick={drawCard} disabled={!isTurnToDraw()} variant="contained" >Draw Card</Button>
            <FormGroup sx={{alignContent: "center"}}>
                <FormControlLabel control={<Switch checked={autoDraw} onChange={onAutoDrawToggled}/>} label="AutoDraw" />
            </FormGroup>
            <div>
            {autoDraw ?
            <FormControl>
                <RadioGroup
                    row
                    value={autoDrawSpeed}
                    onChange={autoDrawSpeedChanged}
                >
                    <FormControlLabel value="slow" control={<Radio />} label="Slow" />
                    <FormControlLabel value="medium" control={<Radio />} label="Medium" />
                    <FormControlLabel value="fast" control={<Radio />} label="Fast" />
                </RadioGroup>
            </FormControl>
            : null}
            </div>
        </div>
    )
}

export default DeckDraw;
