export const BASE_URL = "bigzaffo-server-eeujukkgfa-uc.a.run.app";
// for local dev
//export const BASE_URL = "localhost:8080";
export const HTTP_BASE_URL = BASE_URL.includes("localhost:8080") ? "http://" + BASE_URL : "https://" + BASE_URL;
export const WS_BASE_URL = BASE_URL.includes("localhost:8080") ? "ws://" + BASE_URL : "wss://" + BASE_URL;

export const nonDealerTeamColor = "#F9ECF8";
export const dealerTeamColor = "#E8D3E0";

export const trumpNumberOptions = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "Jack", "Queen", "King", "Ace", "Joker"]
