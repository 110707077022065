import { useState, useRef} from "react";
import { Button, TextField } from '@mui/material';
import { gameState } from "../common/models";
import GameView from './GameView'
import GameCreateJoin from "./GameCreateJoin";
import { makeGetRequest } from "../common/helpers";
import { WS_BASE_URL } from "../common/constants";
import bag from "../images/zaffo_full.jpg";

const Game: React.FC = () => {
    const [playerId, setPlayerId] = useState("")
    const [playerIdIsSet, setPlayerIdIsSet] = useState(false)
    const [isConnectedToGame, setIsConnectedToGame] = useState(false)
    const [currentGameState, setCurrentGameState] = useState<gameState>()
    const wsConnection: React.MutableRefObject<WebSocket | undefined> = useRef(undefined)
    const gameId = useRef("")

    function getGame() {
        makeGetRequest("game/" + gameId.current, (jsonResponse: gameState) => {
            setCurrentGameState(jsonResponse);
        });
    }

    function connectToGameRoom(gameId: string, isNewGame: boolean) {
        if (window["WebSocket"]) {
            if (wsConnection.current != undefined && isNewGame) {
                wsConnection.current.close(3333, "new game"); // close because of new game
            }
            wsConnection.current = new WebSocket(WS_BASE_URL + "/api/v1/ws/" + gameId);
            wsConnection.current.onclose = function (event: CloseEvent) {
                console.log("ws connection closed", event.code, event.reason);
                if (event.code == 3333) { // new game triggered close, do not reconnect
                    console.log("new game triggered close");
                    return;
                }
                
                setTimeout(function() {
                    connectToGameRoom(gameId, false);
                  }, 1000);
            };

            wsConnection.current.onmessage = onMessageFromGameRoom;
            setIsConnectedToGame(true);
        }
    }

    function onMessageFromGameRoom(event: MessageEvent<any>) {
        //let message = event.data;
        //console.log(message);
        getGame();
    }

    function playerIdChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setPlayerId(e.target.value)
    }

    function gameDataLoaded(): boolean {
        return typeof currentGameState !== 'undefined'
    }

    function confirmPlayerId() {
        setPlayerIdIsSet(true);
    }

    function renderSetPlayerId(): JSX.Element | null {
        if (playerIdIsSet) {
            return null;
        }

        return (
            <div>
                <h2>Please enter your name</h2>
                <TextField style={{caretColor: "auto"}} label="Player Name" variant="standard" onChange={playerIdChanged}/>
                <br></br>
                <Button style={{backgroundColor: "#7B4157", margin: "10px"}} onClick= {confirmPlayerId} variant="contained">Confirm Name</Button>
            </div>
        )
    }

    return (
      <div style={{
        padding: "10px",
        width: "100%"
      }}>
        { !gameDataLoaded() ? <img style={{height: "200px", margin: "10px"}} src={bag} alt="Zaffo"/> : null }
        {renderSetPlayerId()}
        { !playerIdIsSet || isConnectedToGame ? null :<GameCreateJoin gameId={gameId} connectToGameRoom={connectToGameRoom} playerId={playerId}></GameCreateJoin>}
        { gameDataLoaded() ? <GameView gameState={currentGameState!} playerId={playerId} connectToGameRoom={connectToGameRoom} gameId={gameId}></GameView> : null }
      </div>
    );
  }
  
  export default Game;
  