import {PlayGameProps} from "../../common/models"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getSuitImage, isPlayingJoker } from "../../common/helpers";
import { trumpNumberOptions } from "../../common/constants";

const TrumpInfoDisplay: React.FC<PlayGameProps> = ({gameState, playerId}) => {
    function getTrumpNumberDisplay(): string {
        return trumpNumberOptions[gameState.trumpNumber - 2];
    }

    function getTrumpSuitDisplay(): string | JSX.Element {
        if (gameState.trumpSuit == "undecided") {
            return "?";
        }

        return <img style={{width: "30px"}} src={getSuitImage(gameState.trumpSuit)}/>
    }

    function getTrumpDeclarerDisplay(): string {
        if (gameState.trumpDeclarePlayerIndex == -1) {
            return "?";
        }
        if (gameState.trumpDeclarePlayerIndex == -2) {
            return "底牌";
        }

        return gameState.playerIds[gameState.trumpDeclarePlayerIndex]
    }
    
    function getTrumpIsSecuredDisplay(): string {
        if (gameState.trumpSuit == "undecided") {
            return "?";
        }

        return gameState.trumpSecured ? "Yes" : "No";
    }

    function renderTableHeaderRow(): JSX.Element {
        if (isPlayingJoker(gameState)) {
            return (
                <TableRow>
                    <TableCell align="center">主 number</TableCell>
                </TableRow>
            )
        }

        return (
            <TableRow>
                <TableCell align="center">主 number</TableCell>
                <TableCell align="center">主 suit</TableCell>
                <TableCell align="center">Player who declared 主</TableCell>
                <TableCell align="center">主 is secured</TableCell>
            </TableRow>
        )
    }

    function renderTableDataRow(): JSX.Element {
        if (isPlayingJoker(gameState)) {
            return (
                <TableRow>
                    <TableCell align="center">{getTrumpNumberDisplay()}</TableCell>
                </TableRow>
            )
        }

        return (
            <TableRow>
                <TableCell align="center">{getTrumpNumberDisplay()}</TableCell>
                <TableCell align="center">{getTrumpSuitDisplay()}</TableCell>
                <TableCell align="center">{getTrumpDeclarerDisplay()}</TableCell>
                <TableCell align="center">{getTrumpIsSecuredDisplay()}</TableCell>
            </TableRow>
        )
    }

    return (
        <div>
            <TableContainer component={Paper} sx={{borderRadius: "10px", minWidth: "30vw", maxWidth: 650, marginLeft: "auto", marginRight: "auto"}}>
                <Table sx={{ minWidth: "30vw"}} size="small">
                    <TableHead>
                        {renderTableHeaderRow()}
                    </TableHead>
                    <TableBody>
                        {renderTableDataRow()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TrumpInfoDisplay;
